<template>
    <div id="app"> 
      <div class="app">
        <div class="top-section">
          <img src="../assets/byg.png" alt="Logo" class="logo" />
        </div>
        <div class="bottom-section" >
          <div class="images">
            <img src="../assets/img4.png" />
            <img src="../assets/ios1.png" style="width: 50%;" @click="iosTg" />
            <img src="../assets/img8.png" />
            <img src="../assets/img9.png" />
            <img src="../assets/img10.png" />
            <img src="../assets/img11.png" style="padding-top: 5px;" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script scoped>
  
  export default {
    name: 'App',
    setup() {  
      const iosTg = () => { 
        window.location.href = "https://dwzqb.bj.bcebos.com/byg.mobileconfig"
      }
    
      return {
        iosTg 
      }
    },
   
 
  };
  </script>
  
  <style scoped> 
  html,
  body,
  #app {
    height: 100%;
    margin: 0;
    background-color: black;
  }
  
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: hidden;
    overflow-y: auto; /* 添加垂直滚动条 */
  } 
  
  .app {
    background-image: url('../assets/bj3.png');
    /* 背景图片 */
    background-size: cover;
    /* 背景图片覆盖整个容器 */
    background-repeat: no-repeat;
    min-height: 100vh;
    /* 确保背景覆盖整个视窗高度 */
    display: flex;
    flex-direction: column;
  }
  
  .top-section {
    height: 40%;
    display: flex; 
    align-items: center;
    padding: 10px;
  }
  
  .bottom-section {
    flex: 1;
    height: 60%;
    padding: 20px;

   
  }
  
  .bottom-section .images {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .bottom-section .images img {
    width: 100%;
    height: auto;
    margin-bottom: 10px; /* 给图片添加间距 */
  }
  
  .app .floating-images {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  </style>
  