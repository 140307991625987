<template>
  <div id="app" >
    <div class="pc" v-if="!isMobile">
      <div class="container">
      <div class="left">
        <img class="logo" src="../assets/byg.png" alt="Logo" />
      </div>
      <div class="right">
        <div class="images">
          <img src="../assets/img1.png" alt="Image 1" />
          <img src="../assets/img2.png" alt="Image 2"  @click="contactInfo('https://t.me/byg141')" />
          <img src="../assets/img3.png" alt="Image 3" @click="contactInfo('https://t.me/seobab0')"  />
        </div>
        <div class="buttons">
          <img src="../assets/android.png" @click="downloadApp" />
          <img src="../assets/ios.png" @click="iosTg" />
        </div>
      </div>
    </div>
    <div class="floating-images">
      <img src="../assets/1.png" alt="Floating Image 1" />
      <img src="../assets/2.png" alt="Floating Image 1" />
      <img src="../assets/3.png" alt="Floating Image 1" />
      <img src="../assets/4.png" alt="Floating Image 1" />
      <img src="../assets/5.png" alt="Floating Image 1" />
      <img src="../assets/6.png" alt="Floating Image 1" />
      <img src="../assets/7.png" alt="Floating Image 1" />
      <img src="../assets/8.png" alt="Floating Image 1" />
      <img src="../assets/9.png" alt="Floating Image 1" />
      <img src="../assets/10.png" alt="Floating Image 1" />
      <img src="../assets/11.png" alt="Floating Image 1" />
      <img src="../assets/12.png" alt="Floating Image 1" />
      <img src="../assets/13.png" alt="Floating Image 1" />
      <img src="../assets/14.png" alt="Floating Image 1" />
      <img src="../assets/15.png" alt="Floating Image 1" />
    </div>

    </div>

    <div v-else class="app">
      <div class="top-section">
      <img src="../assets/byg.png" alt="Logo" class="logo" />
    </div>
    <div class="bottom-section">
      <div class="images">
          <img src="../assets/img4.png" />
          <img src="../assets/img5.png" @click="contactInfo('https://t.me/byg141')" />
          <img src="../assets/img6.png"  @click="contactInfo('https://t.me/seobab0')" />
     </div>
     <div class="floating-images">
      <img src="../assets/1.png" alt="Floating Image 1" />
      <img src="../assets/2.png" alt="Floating Image 1" />
      <img src="../assets/3.png" alt="Floating Image 1" />
      <img src="../assets/4.png" alt="Floating Image 1" />
      <img src="../assets/5.png" alt="Floating Image 1" />
      <img src="../assets/6.png" alt="Floating Image 1" />
      <img src="../assets/7.png" alt="Floating Image 1" />
      <img src="../assets/8.png" alt="Floating Image 1" />
      <img src="../assets/9.png" alt="Floating Image 1" />
      <img src="../assets/10.png" alt="Floating Image 1" />
      <img src="../assets/11.png" alt="Floating Image 1" />
      <img src="../assets/12.png" alt="Floating Image 1" />
      <img src="../assets/13.png" alt="Floating Image 1" />
      <img src="../assets/14.png" alt="Floating Image 1" />
      <img src="../assets/15.png" alt="Floating Image 1" />
    </div>
    <div class="dowdbut">
      <div class="buttons">
          <img src="../assets/android.png" @click="downloadApp" />
          <img src="../assets/ios.png"  @click="iosTg" />
        </div>
    </div>
    </div> 

  </div>

  </div>
  
</template>

<script>
import { ref } from 'vue'
import router from '@/router/index'
import { useRoute } from 'vue-router'
import axios from 'axios';

export default {
  name: 'App',
  setup() { 
    const route = useRoute() 
    const iosTg = () => {
      var queryString = window.location.search;
      router.push('/ios' + queryString);
    }

    const downloadApp = () => {
      var queryString = window.location.search;
      // if (queryString) {
      //   window.location.href = "/dowd" + queryString + "&type=1"
      // } else {
      //   window.location.href = "/dowd?type=1"
      // }
      window.location.href = "https://dwzqb.bj.bcebos.com/byg.apk"
    }
    const contactInfo=(str)=>{
     
      const externalLink = str;
      const newWindow = window.open(externalLink, '_blank');
      newWindow.focus()
    }

    const getHttp = () => {
      var queryString = window.location.search;
      if (queryString.length == 0) {
        queryString = "?type=1"
      } else {
        queryString = queryString + "&type=1"
      }
      axios.post('/api/addVisit' + queryString)
        .then(response => {
          // Handle successful response

        })
        .catch(error => {
          // Handle error

        });
    }

    //getHttp();

    return {
      iosTg,
      downloadApp, 
      contactInfo,
    }
  },

  data() {


    return {
      isMobile: false,
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 1023;
    },
  },
};
</script>

<style  >
 
html, body, #app {
  height: 100%;
  margin: 0;
}
 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden; /* 防止溢出 */
  overflow-y: auto; /* 添加垂直滚动条 */
  background-color: black;
  
}
.pc{
  background-image: url('../assets/bj.png'); /* 背景图片 */
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  height: 100%; 
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%; 
  box-sizing: border-box;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px;
  box-sizing: border-box;
  overflow: hidden; /* 防止溢出 */
}

.logo {
  align-self: flex-start; 
  height: auto;
  max-width:50%;
  border-radius: 8px; 
  min-width: 150px;
 
}

.right {
  flex: 1; 
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* 防止溢出 */ 
}

.right .images {
    display: flex;
    justify-content: space-around;
    align-items: center; 
    flex-direction: column;
    padding-top: 20px;
    
}

.right .images img { 
  padding-bottom: 20px;
  border-radius: 8px;
  object-fit: cover;  
  max-width:90%; 
  min-width: 150px;
}

.pc .buttons { 
  align-items: center;
 
}

.pc .buttons img { 
  margin: 10px; 
  border-radius: 8px;
  object-fit: cover; 
  max-width:40%; 
  min-width: 150px;
}


.pc .floating-images {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
  padding: 10px 0;
}

.pc .floating-images img {
  margin: 5px; 
  height: auto;
  max-width:15%; 
  min-width: 50px;
}

.app{ 
  background-image: url('../assets/bj3.png'); /* 背景图片 */
  background-size: cover; /* 背景图片覆盖整个容器 */
  height: 100%; 

}


.top-section {
   height: 40%;
  display: flex;
  padding: 10px;
} 

.bottom-section {
  flex: 1; 
  height: 60%;
}
 

.bottom-section .images img {  
  width:100%;  
  height: 10%;
}


.app .floating-images {  
  width: 100%; 
  justify-content: center;
  flex-wrap: wrap;  
}

.app .floating-images img {
  margin: 5px; 
  height: auto;
  width: 40px;
}

.dowdbut{ 
  background-image: url('../assets/img7.png'); /* 背景图片 */
  background-size: cover; /* 背景图片覆盖整个容器 */
  margin-top: 5px;
  
}

.app .buttons { 
  align-items: center;
 
} 
.app .buttons img { 
  margin: 10px; 
  border-radius: 8px;
  object-fit: cover; 
  max-width:40%; 
  min-width: 150px;
}


</style>
